import { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'

import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { getListTickets } from '../core/_requests'
import { PrepareTicketsToObject, TicketListObject } from '../core/_models'
import ActiveTicketsFilter from './ActiveTicketsFilter'
import ActiveTicketItem from './ActiveTicketItem'
import { ROLE, useAuth } from '../../auth'
import { IsGranted } from '../../auth/PrivateRoute'
import { ETicketStatus } from '../../../../types/enums'
import { Table } from '../../../../components/Table'
import { TableHead } from '../../../../components/Table/TableHead'
import { TableBody } from '../../../../components/Table/TableBody'
import { NewTicket } from '../../../../components/NewTicket'

import '../../../assets/css/pagination.css'
import { Merchant } from '../../../common/core/_models'
import { getListMerchantsForSelect } from '../../../common/core/_requests'

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Активные',
    path: '/payment-tickets/active',
    isSeparator: false,
    isActive: true,
    roles: [
      ROLE.ROLE_ADMIN,
      ROLE.ROLE_MANAGER,
      ROLE.ROLE_CASHIER,
      ROLE.ROLE_SENIOR_CASHIER,
      ROLE.ROLE_SENIOR_OPERATOR,
      ROLE.ROLE_OPERATOR,
    ],
  },
  {
    title: 'Завершённые',
    path: '/payment-tickets/finished',
    isSeparator: false,
    isActive: false,
    roles: [
      ROLE.ROLE_ADMIN,
      ROLE.ROLE_MANAGER,
      ROLE.ROLE_CASHIER,
      ROLE.ROLE_SENIOR_CASHIER,
      ROLE.ROLE_SENIOR_OPERATOR,
      ROLE.ROLE_OPERATOR,
    ],
  },
  {
    title: 'Отчёт',
    path: '/payment-tickets/report',
    isSeparator: false,
    isActive: false,
    roles: [
      ROLE.ROLE_ADMIN,
      ROLE.ROLE_MANAGER,
      ROLE.ROLE_SENIOR_CASHIER,
      ROLE.ROLE_SENIOR_OPERATOR,
    ],
  },
]

const defaultTicketList: TicketListObject = {}
const perPage = 20

const ActiveTickets = () => {
  const { currentUser } = useAuth()
  const [filters, setFilters] = useState({
    euConfirmed: false,
    perPage: perPage,
    status: [
      ETicketStatus.WAITING_FOR_REQUISITE,
      ETicketStatus.WAITING_FOR_SMS,
      ETicketStatus.WAITING_FOR_MERCHANT,
      ETicketStatus.WAITING_FOR_ACTION,
      ETicketStatus.NOT_CONFIRMED,
      ETicketStatus.CLIENT_PENDING,
      ETicketStatus.PAYMENT_PENDING,
    ],
  })
  const [tickets, setTickets] = useState(defaultTicketList)
  const [isLoading, setIsLoading] = useState(false)
  const [countPages, setCountPages] = useState(0)
  const [fullCount, setFullCount] = useState(0)
  const [showAlert, setShowAlert] = useState(false)
  const [merchantsList, setMerchantsList] = useState<Array<Merchant>>([])

  const getTicketList = async (page: number, reset: boolean = false) => {
    setIsLoading(true)
    let response

    if (reset) {
      response = await getListTickets(
        {
          euConfirmed: false,
          status: [
            ETicketStatus.WAITING_FOR_REQUISITE,
            ETicketStatus.WAITING_FOR_SMS,
            ETicketStatus.WAITING_FOR_MERCHANT,
            ETicketStatus.WAITING_FOR_ACTION,
            ETicketStatus.NOT_CONFIRMED,
            ETicketStatus.CLIENT_PENDING,
            ETicketStatus.PAYMENT_PENDING,
          ],
          perPage: 20,
          page: 1,
        },
        currentUser
      )
    } else {
      response = await getListTickets(
        {
          ...filters,
          page,
        },
        currentUser
      )
    }

    if (!response.data) {
      return false
    }

    if (response.data.success && response.data.tickets) {
      setTickets({ ...PrepareTicketsToObject(response.data.tickets) })
      setCountPages(Math.ceil(response.data.totalRows / perPage))
      setFullCount(response.data.totalRows)
      setIsLoading(false)
    }
  }

  const removeTicketFromList = (token: string) => {
    let ticketsClone = Object.assign(tickets, {})

    delete ticketsClone[token]

    setTickets(ticketsClone)
    setFullCount(fullCount - 1)
  }

  useEffect(() => {
    getTicketList(1)
    getListMerchantsForSelect(currentUser).then((res) =>
      setMerchantsList(res?.data?.merchants || [])
    )
  }, [])

  return (
    <>
      {IsGranted(
        [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_SENIOR_OPERATOR, ROLE.ROLE_OPERATOR],
        currentUser
      ) && (
        <div className='row'>
          <div className='col text-end pb-4'>
            <NewTicket callback={getTicketList} />
          </div>
        </div>
      )}

      <div className='card'>
        <PageTitle breadcrumbs={accountBreadCrumbs}>Активные</PageTitle>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Активные тикеты</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Количество: {fullCount}</span>
          </h3>
        </div>

        <div className='card mb-7'>
          <div className='card-body border-0 pt-5'>
            <ActiveTicketsFilter
              initialFilter={filters}
              updateFilters={setFilters}
              callback={getTicketList}
            />
          </div>
        </div>

        <div className='card-body py-3'>
          {showAlert && (
            <div
              style={{
                top: '10vh',
                left: '50%',
                zIndex: 10000,
                transform: 'translate(-50%, -50%)',
              }}
              className='alert alert-success position-fixed min-w-200px w-300px'
            >
              Скопировано
            </div>
          )}
          <Table>
            <TableHead>
              <tr>
                <th className='min-w-120px'>ID</th>
                <th className='min-w-120px'>Дата</th>
                {IsGranted(
                  [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_CASHIER, ROLE.ROLE_SENIOR_CASHIER],
                  currentUser
                ) && <th className='min-w-120px'>Сумма</th>}
                <th className='min-w-200px'>Название/Реквизит</th>
                <th className='min-w-150px'>Источник создания</th>
                <th className='min-w-150px'>Смс</th>
                {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER], currentUser) && (
                  <th className='min-w-120px'>Мерчант</th>
                )}
                <th className='min-w-120px'>Файл</th>
                <th className='min-w-150px'></th>
              </tr>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <tr>
                  <td colSpan={10}>Загрузка...</td>
                </tr>
              ) : (
                <>
                  {Object.keys(tickets).map((key) => (
                    <ActiveTicketItem
                      ticket={tickets[key]}
                      key={key}
                      merchants={merchantsList}
                      removeTicketFromList={removeTicketFromList}
                      updateTicketList={() => getTicketList(1)}
                      setShowAlert={setShowAlert}
                    />
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </div>

        <ReactPaginate
          activeClassName={'active-pagination '}
          breakClassName={'item-pagination break-me-pagination '}
          containerClassName={'pagination-pagination'}
          disabledClassName={'disabled-page-pagination'}
          nextClassName={'item-pagination ki-duotone ki-right fs-2x'}
          pageClassName={'item-pagination pagination-page-pagination '}
          previousClassName={'item-pagination ki-duotone ki-left fs-2x'}
          breakLabel='...'
          nextLabel=''
          onPageChange={(pageNumber) => getTicketList(pageNumber.selected + 1)}
          pageRangeDisplayed={5}
          pageCount={countPages}
          previousLabel=''
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  )
}

export default ActiveTickets
